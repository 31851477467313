<template>
	<b-modal
		ref="productReviewModal"
		no-close-on-backdrop
		no-close-on-esc
		header-class="mx-auto w-100 bg-dark text-white"
		size="md"
		body-class="p-0"
		centered
		@hide="close()">
		<template #modal-footer>
			<b-button
				@click="close()">
				{{ translate('cancel') }}
			</b-button>
			<b-button
				variant="primary"
				:disabled="loading || review.title === '' || review.comment === '' || !review.rating"
				@click="confirm()">
				<i
					v-if="loading"
					class="fa fa-fw fa-spinner fa-pulse" />
				{{ translate('save') }}
			</b-button>
		</template>
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('product_review') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close()">
				×
			</button>
		</template>
		<div class="d-flex flex-column align-items-center justify-content-center mx-3 mt-2">
			<img
				v-if="review.product_image"
				loading="lazy"
				:src="review.product_image"
				alt="Product Image"
				class="responsive block-inline"
				style="max-width:84px">
			<h5
				v-if="review.product_name"
				class="m-0 text-center">
				{{ review.product_name }}
			</h5>
		</div>
		<hr class="my-2 mx-3">
		<b-form class="mx-3 mt-3">
			<label for="rating-stars">{{ translate('rating') }}:</label>
			<div class="d-flex">
				<b-form-rating
					id="rating-stars"
					v-model="review.rating"
					variant="primary"
					no-border
					precision="1" />
				<b-form-input
					v-model="review.rating"
					style="width: 80px; text-align: center"
					type="number"
					step="0.1"
					min="0"
					max="5" />
			</div>
			<template v-if="hasError('rating')">
				<span
					v-for="error in errors['rating']"
					:key="error"
					class="custom-invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
			<b-form-group class="mt-3">
				<label for="title">{{ translate('title') }}:</label>
				<b-form-input
					id="title"
					v-model="review.title"
					:placeholder="translate('title_placeholder')" />
				<template v-if="hasError('title')">
					<span
						v-for="error in errors['title']"
						:key="error"
						class="custom-invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</b-form-group>
			<b-form-group class="mt-3">
				<label for="comment">{{ translate('comment') }}:</label>
				<b-form-textarea
					id="comment"
					v-model="review.comment"
					style="color: black"
					rows="3"
					max-rows="6"
					:placeholder="translate('comment_placeholder')" />
				<template v-if="hasError('comment')">
					<span
						v-for="error in errors['comment']"
						:key="error"
						class="custom-invalid-feedback animated fadeIn"
						v-text="error" />
				</template>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>

import { ProductReviews as ProductReviewsMessages } from '@/translations';
import ProductReviews from '@/util/ProductReviews';
import { UNPROCESSABLE } from '@/settings/Errors';
import EventBus from '@/util/eventBus';

export default {
	name: 'ProductReviewModal',
	messages: [ProductReviewsMessages],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		reviewInfo: {
			type: Object,
			required: true,
		},
		action: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			productReview: new ProductReviews(),
			alert: new this.$Alert(),
			review: { ...this.reviewInfo },
			errors: {},
		};
	},
	computed: {
		loading() {
			return this.productReview.data.loading;
		},
	},
	watch: {
		show() {
			if (this.show) {
				this.open();
			} else {
				this.close();
			}
		},
		reviewInfo: {
			handler() {
				this.review = { ...this.reviewInfo };
			},
			immediate: true,
			deep: true,
		},
		'review.rating': {
			handler() {
				this.$delete(this.errors, 'rating');
			},
		},
		'review.title': {
			handler() {
				this.$delete(this.errors, 'title');
			},
		},
		'review.comment': {
			handler() {
				this.$delete(this.errors, 'comment');
			},
		},
	},
	methods: {
		open() {
			this.review = { ...this.reviewInfo };
			this.$refs.productReviewModal.show();
			this.$emit('update:show', true);
		},
		close() {
			this.errors = {};
			this.$refs.productReviewModal.hide();
			this.$emit('update:show', false);
		},
		confirm() {
			if (this.action === 'create') {
				this.create();
			} else {
				this.update();
			}
		},
		create() {
			this.productReview.createProductReview({
				product_id: this.review.product_id,
				rating: this.review.rating,
				title: this.review.title,
				comment: this.review.comment,
			}).then(() => {
				this.alert.toast('success', this.translate('successfully_submitted'));
				this.$emit('created');
				this.close();
			}).catch(() => {
				const { status, errors } = this.productReview.errors;
				if (UNPROCESSABLE.includes(status)) {
					// eslint-disable-next-line no-restricted-syntax
					for (const error of Object.keys(errors)) {
						this.$set(this.errors, error, errors[error]);
					}
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
				this.$emit('error');
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
		update() {
			this.productReview.updateReview(this.review.id, {
				rating: this.review.rating,
				title: this.review.title,
				comment: this.review.comment,
			}).then(() => {
				this.alert.toast('success', this.translate('successfully_updated'));
				this.$emit('updated');
				this.close();
			}).catch(() => {
				const { status, errors } = this.productReview.errors;
				if (UNPROCESSABLE.includes(status)) {
					// eslint-disable-next-line no-restricted-syntax
					for (const error of Object.keys(errors)) {
						this.$set(this.errors, error, errors[error]);
					}
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
				this.$emit('error');
			}).finally(() => {
				EventBus.$emit('updateAccountSteps');
			});
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		cancel() {
			this.close();
			this.$emit('cancel');
		},
	},
};
</script>
