<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div
			v-if="!admin.includes(this.$user.details().type)"
			class="row mb-2">
			<div class="col">
				<div class="alert alert-info w-100">
					<ul
						style="margin: 0; padding: 10px"
						v-html="translate('pending_reviews_alert')" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="toggleFilters()">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearReviewFilters">
			<template #form>
				<div class="row">
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<search
							v-model="filters.user_id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{ role:'distributor,customer,affiliate' }"
							:init-value="filters.user_id"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div class="form-group">
							<label
								for="rating"
								class="label">{{ translate('rating') }}</label>
							<select
								id="rating"
								v-model="filters.rating"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="rating in ratings"
									:key="rating"
									:value="rating">
									{{ translate('x_star', { x: rating }) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-sm">
						<div class="form-group">
							<label
								for="dateRange"
								class="label">
								{{ translate('date_range') }}
							</label>
							<select
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="range in dateRanges"
									:key="range"
									:value="range">
									{{ translate(range) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="visibility"
								class="label">{{ translate('visibility') }}</label>
							<select
								id="visibility"
								v-model="filters.visibility"
								name="visibility"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in visibility"
									:key="item"
									:value="item">
									{{ translate(item) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="is_reported"
								class="label">{{ translate('is_reported') }}</label>
							<select
								id="is_reported"
								v-model="filters.is_reported"
								name="is_reported"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in reportedStatus"
									:key="item"
									:value="item">
									{{ translate(item) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-12">
						<div
							id="formSelector"
							class="form-group">
							<label
								for="products"
								class="label">{{ translate('product') }}</label>
							<multiselect
								:items-import="products"
								:loading="productsLoading"
								:selected-items-import="selectedProducts"
								:selected-items.sync="selectedProducts"
								:placeholder="translate('products')"
								:close="showFilters" />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-button-group>
			<b-button
				v-for="column in orderBy.filter((item) => (item === 'times_reported' && admin.includes($user.details().type)) || item !== 'times_reported')"
				:key="column"
				:variant="column === sortCol ? 'primary' : 'secondary'"
				@click="sortCol = column; getDataFiltered()"
				v-text="translate(column)" />
		</b-button-group>
		<template v-if="!loading">
			<div class="product-reviews-list my-3">
				<div
					v-for="(item, index) in data"
					:key="index"
					style="position: relative"
					class="review-item w-100">
					<button
						v-if="$can('product_reviews', 'update') && item.attributes.comment !== null"
						v-b-tooltip.hover.left
						:title="translate('edit_review')"
						class="btn btn-secondary btn-sm"
						style="position: absolute; right: 5px; top: 5px"
						@click="edit(item)">
						<i class="fas fa-edit" />
					</button>
					<button
						v-if="$can('product_reviews', 'hide') && item.attributes.comment !== null"
						v-b-tooltip.hover.left
						:title="translate(item.attributes.hidden ? 'show_review' : 'hide_review')"
						class="btn btn-secondary btn-sm"
						style="position: absolute; right: 5px; top: 5px"
						@click="hide(item)">
						<i :class="{ 'fas fa-eye-slash': item.attributes.hidden, 'fas fa-eye': !item.attributes.hidden }" />
					</button>
					<span
						v-if="admin.includes($user.details().type) && item.attributes.times_reported > 0"
						v-b-tooltip.hover.left
						:title="translate('times_reported')"
						style="position: absolute; cursor: pointer; right: 8px; top: 38px; display: flex; justify-content: center; align-items: center; width: 25px; height: 25px; font-weight: bold; color: white; background-color: #cb1313; border-radius: 100%"
						@click="$router.push({ name: 'ReviewReports', params: { reviewId: item.id } })">
						{{ item.attributes.times_reported }}
					</span>
					<div
						:class="{ 'h-100': item.attributes.comment === null }"
						class="d-flex">
						<div
							class="d-flex flex-column align-items-center"
							style="width: 100px; min-width: 100px;">
							<img
								loading="lazy"
								:src="item.attributes.product.image"
								alt="Product Image"
								class="responsive block-inline"
								style="max-width:56px">
							<p class="m-0 text-center px-2">
								{{ translate(item.attributes.product.code_name) }}
							</p>
						</div>
						<div class="h-100 d-flex align-items-center">
							<b-button
								v-if="item.attributes.comment === null"
								variant="btn btn-outline-primary"
								@click="create(item)">
								{{ translate('write_a_review') }}
								<i class="fas fa-edit" />
							</b-button>
							<article v-else>
								<h5 class="m-0">
									{{ item.attributes.user.shortened_name }}
								</h5>
								<div class="d-flex align-items-center flex-wrap">
									<div class="d-flex justify-content-start">
										<b-rating
											:value="item.attributes.rating"
											variant="primary"
											style="padding-left: 0; padding-top: 0; padding-bottom: 0"
											inline
											no-border
											show-value-max
											show-value
											readonly />
									</div>
									<span
										class="text-muted text-medium"
										style="margin-right: 35px">
										{{ item.attributes.created_at.date ? $moment(item.attributes.created_at.date).format(dateFormat) : $moment(item.attributes.created_at).format(dateFormat) }}
									</span>
								</div>
								<h6>{{ item.attributes.title }}</h6>
								<p class="m-0">
									{{ item.attributes.comment }}
								</p>
								<p
									v-if="item.attributes.edited"
									class="m-0 text-muted text-medium"
									style="text-transform: lowercase">
									({{ translate('edited') }})
								</p>
							</article>
						</div>
					</div>
				</div>
			</div>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('no_pending_reviews')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getNewPage" />
		<product-review-modal
			:show.sync="showModal"
			:review-info="reviewInfo"
			:action="action"
			@created="getNewPage()"
			@updated="getNewPage()" />
	</div>
</template>
<script>
import Multiselect from '@/components/Multiselect';
import { Grids, ProductReviews as ReviewsMessages, Products } from '@/translations';
import { DATE_RANGES as dateRanges, MMMDY_FORMAT } from '@/settings/Dates';
import ProductReviews from '@/util/ProductReviews';
import ProductReviewModal from '@/views/ProductReviews/components/ProductReviewModal.vue';
import DataFilter from '@/components/DataFilter/index.vue';
import FiltersParams from '@/mixins/FiltersParams';
import { admin } from '@/settings/Roles';
import { SEARCH_USERS } from '@/config/endpoint';
import { QUERY_VARS as noFilterable, PAGINATION } from '@/settings/RequestReply';

export default {
	name: 'ReviewedProducts',
	components: { DataFilter, ProductReviewModal, Multiselect },
	messages: [Grids, Products, ReviewsMessages],
	mixins: [FiltersParams],
	props: {
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			productReviews: new ProductReviews(),
			hideProductReviews: new ProductReviews(),
			productReviewsProducts: new ProductReviews(),
			alert: new this.$Alert(),
			dateFormat: MMMDY_FORMAT,
			showModal: false,
			currentPage: 1,
			action: 'update',
			reviewInfo: {
				product_id: 0,
				product_name: '',
				product_image: '',
				rating: 5,
				title: '',
				comment: '',
			},
			admin,
			endpoint: SEARCH_USERS.endpoint,
			ratings: [5, 4, 3, 2, 1, 0],
			visibility: ['hidden', 'visible'],
			reportedStatus: ['yes', 'no_word'],
			orderBy: ['newest', 'rating', 'times_reported'],
			dateRanges,
			sortCol: undefined,
			products: [],
			selectedProducts: [],
		};
	},
	computed: {
		loading() {
			return !!this.productReviews.data.loading;
		},
		pagination() {
			return this.productReviews.data.pagination;
		},
		data() {
			try {
				const { data } = this.productReviews.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		productsLoading() {
			return !!this.productReviewsProducts.data.loading;
		},
	},
	watch: {
		language() {
			this.products = this.products.map((item) => ({
				...item,
				text: this.translate(item.translate_key),
			}));
			if (typeof this.selectedProducts === 'object') {
				this.selectedProducts = this.selectedProducts.map((item) => ({
					...item,
					text: this.translate(item.translate_key),
				}));
			}
		},
	},
	mounted() {
		this.sortCol = this.$route.query.sortCol ?? 'newest';
		this.dateRange = this.$route.query.dateRange ?? '';
		this.productReviews.getReviews();
		this.getMultiselectProducts();

		this.noFilterable.push(PAGINATION);
	},
	methods: {
		getMultiselectProducts() {
			this.productReviewsProducts.getAllowedProductsToReview().then((response) => {
				this.selectedProducts = this.filters.products;
				this.products = response.map((item) => ({
					value: item.id,
					translate_key: item.attributes.code_name,
					text: this.translate(item.attributes.code_name),
				}));
			});
		},
		hide(item) {
			this.hideProductReviews.hideProductReview(item.id, item.attributes.hidden ? 0 : 1)
				.then(() => {
					this.alert.toast('success', this.translate('successfully_updated'));
					this.getNewPage();
				})
				.catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
		},
		edit(item) {
			this.reviewInfo.id = item.id;
			this.reviewInfo.product_id = item.attributes.product.id;
			this.reviewInfo.product_image = item.attributes.product.image;
			this.reviewInfo.product_name = this.translate(item.attributes.product.code_name);
			this.reviewInfo.rating = item.attributes.rating;
			this.reviewInfo.title = item.attributes.title;
			this.reviewInfo.comment = item.attributes.comment;
			this.action = 'update';
			this.showModal = true;
		},
		create(item) {
			this.reviewInfo.product_id = item.attributes.product.id;
			this.reviewInfo.product_image = item.attributes.product.image;
			this.reviewInfo.product_name = this.translate(item.attributes.product.code_name);
			this.reviewInfo.rating = 5;
			this.reviewInfo.title = '';
			this.reviewInfo.comment = '';
			this.action = 'create';
			this.showModal = true;
		},
		getNewPage(page) {
			const options = {};
			if (typeof page === 'number') {
				this.currentPage = page;
				options[PAGINATION] = page;
			} else {
				options[PAGINATION] = this.currentPage;
			}

			this.productReviews.getReviews(options);
		},
		toggleFilters() {
			this.showFilters = !this.showFilters;
		},
		getDataFiltered() {
			this.filters.sortCol = this.sortCol;
			if (this.dateRange !== '') {
				this.filters.dateRange = this.dateRange;
			} else {
				delete this.filters.dateRange;
			}
			this.filters.products = this.selectedProducts.map((product) => product.value);
			if (!this.filters.products.length) {
				delete this.filters.products;
			}
			// Remove undefined values from this.filters
			Object.keys(this.filters).forEach((key) => typeof this.filters[key] === 'undefined' && delete this.filters[key]);

			this.$router.push({ name: 'ReviewedProducts', query: this.filters }).catch(() => {});
		},
		clearReviewFilters() {
			const { query, name, params } = this.$route;
			if (typeof query[PAGINATION] !== 'undefined') {
				delete query[PAGINATION];
			}
			Object.keys(this.filters).forEach((item) => {
				if (typeof query[item] !== 'undefined' && !noFilterable.includes(item)) {
					delete query[item];
				}
			});
			this.$router.replace('?');
			this.$router.replace({ name, query, params });
		},
	},
};
</script>
<style scoped>
.product-reviews-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 10px 10px;
	place-items: start;
	align-items: stretch;
}
@media (max-width: 2560px) {
	.product-reviews-list {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
@media (max-width: 1700px) {
	.product-reviews-list {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
@media (max-width: 1420px) {
	.product-reviews-list {
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 768px) {
	.product-reviews-list {
		grid-template-columns: 1fr;
	}
}

.product-reviews-list .review-item {
	border: 1px solid #dee2e6;
	border-radius: 5px;
	padding: 10px;
	position: relative;
}

</style>
