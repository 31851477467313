import {
	GET_PRODUCT_REVIEWS,
	GET_PRODUCT_REVIEW_BY_ID,
	UPDATE_PRODUCT_REVIEW,
	GET_REVIEWS_BY_PRODUCT,
	MARK_REVIEW_AS_HELPFUL,
	CREATE_PRODUCT_REVIEW,
	GET_ALLOWED_PRODUCTS_TO_REVIEW,
	HIDE_SHOW_PRODUCT_REVIEW,
	REPORT_PRODUCT_REVIEW,
	GET_REVIEW_REPORTS,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class ProductReviews {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	getReviews(options) {
		const { method, endpoint } = GET_PRODUCT_REVIEWS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReviewReports(reviewId, page) {
		const { method, endpoint } = GET_REVIEW_REPORTS;
		return this.data[method](endpoint(reviewId), { page }, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReviewById(reviewId) {
		const { method, endpoint } = GET_PRODUCT_REVIEW_BY_ID;
		return this.data[method](endpoint(reviewId), {}, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReviewsByProduct(id, options = {}) {
		const { method, endpoint } = GET_REVIEWS_BY_PRODUCT;
		return this.data[method](endpoint(id), options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateReview(reviewId, options) {
		const { method, endpoint } = UPDATE_PRODUCT_REVIEW;
		return this.data[method](endpoint(reviewId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	markAsHelpful(reviewId, helpful) {
		const { method, endpoint } = MARK_REVIEW_AS_HELPFUL;
		return this.data[method](endpoint(reviewId), { helpful }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllowedProductsToReview() {
		const { method, endpoint } = GET_ALLOWED_PRODUCTS_TO_REVIEW;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createProductReview(options) {
		const { method, endpoint } = CREATE_PRODUCT_REVIEW;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	hideProductReview(reviewId, hide) {
		const { method, endpoint } = HIDE_SHOW_PRODUCT_REVIEW;
		return this.data[method](endpoint(reviewId), { hide }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	reportProductReview(reviewId, reason) {
		const { method, endpoint } = REPORT_PRODUCT_REVIEW;
		return this.data[method](endpoint(reviewId), { report_reason: reason }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default ProductReviews;
