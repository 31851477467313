<template>
	<multiselect
		ref="itemsRef"
		v-model="selectedItems"
		:options="items"
		multiple
		:disabled="loading"
		:close-on-select="false"
		:clear-on-select="false"
		preserve-search
		:preselect-first="false"
		:placeholder="placeholder"
		label="text"
		:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
		track-by="value"
		name="items">
		<template
			slot="selection" />
		<template #option="{option}">
			{{ option.text }}
		</template>
	</multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { ALL_KEY } from '@/settings/Multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'MultiSelect',
	components: {
		Multiselect,
	},
	props: {
		itemsImport: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		selectedItemsImport: {
			type: [String, Array],
			default: () => [],
		},
		placeholder: {
			type: String,
			default: '',
		},
		close: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selectedItems: this.selectedItemsImport,
			itemsOnly: [], // Items without the 'select all' option
			items: this.itemsImport,
		};
	},
	watch: {
		selectedItems() {
			const selectedItems = this.selectedItems.map((item) => item.value);
			const numberOfItems = this.itemsOnly.length;

			if (selectedItems.includes(ALL_KEY) || selectedItems.length === numberOfItems) {
				this.hideSelectAllOption();
				this.selectedItems = this.itemsOnly;
			}

			if (this.selectedItems.length !== numberOfItems) {
				this.showSelectAllOption();
			}

			this.$emit('update:selectedItems', this.selectedItems);
		},
		itemsImport() {
			this.items = this.itemsImport;
			this.selectedItems = this.selectedItemsImport;
			this.init(this.selectedItems);
		},
		language() {
			this.translateSelectAllOption();
		},
		close() {
			if (this.close) {
				setTimeout(() => {
					if (typeof this.$refs.itemsRef !== 'undefined') {
						this.$refs.itemsRef.toggle();
					}
				}, 1);
			}
		},
	},
	mounted() {
		this.init(this.selectedItems);
	},
	methods: {
		translateSelectAllOption() {
			this.hideSelectAllOption();
			this.showSelectAllOption();
		},
		showSelectAllOption() {
			const allOption = this.items.find((item) => item.value === ALL_KEY);
			if (!allOption) {
				this.items.unshift({ value: ALL_KEY, text: this.translate('select_all') });
			}
		},
		hideSelectAllOption() {
			this.items = this.items.filter((item) => item.value !== ALL_KEY);
		},
		init(selectValue) {
			this.translateSelectAllOption();
			this.itemsOnly = this.items.filter((item) => item.value !== ALL_KEY);
			this.selectedItems = this.searchSelectValues(selectValue, this.itemsOnly);
		},
		searchSelectValues(selectValue, searchIn) {
			const values = [];
			let selection = selectValue;

			if (typeof selectValue === 'undefined') {
				return '';
			}

			if (typeof selectValue === 'string') {
				selection = [selectValue];
			}

			if (typeof selectValue[0] !== 'string') {
				return selectValue;
			}
			selection.forEach((value) => {
				const item = searchIn.find((option) => option.value === value);
				if (typeof item !== 'undefined') values.push(item);
			});

			return values;
		},
	},
};
</script>
